<!-- eslint-disable vue/no-deprecated-v-on-native-modifier -->
<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <div>
    <div
      v-for="(iconAction, i) in iconsAction"
      :key="i"
      v-if="canAccess('UI_WORKLOAD:DELETE') && iconAction === 'Delete'
        || canAccess('UI_WORKLOAD:DISABLE') && iconAction === 'Disable' && tableItem.disabled
        || canAccess('UI_WORKLOAD:DISABLE') && iconAction === 'Enable' && !tableItem.disabled"
      class="inline">
      <v-tooltip
        bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-img
            :id="`iiotWorkloadTableActions${iconAction}Image_${index}`"
            :src="`/img/${iconAction}.svg`"
            v-bind="attrs"
            max-height="40px"
            max-width="40px"
            v-on="on"
            @click.native.stop="clickAction(iconAction)"
          />
        </template>
        <span>{{ $t(`defaultTable.${iconAction}`) }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import Logger from '@/utils/logger';

export default {
  props: {
    iconsAction: {
      type: Array,
      default: () => [],
    },
    fetchAction: {
      type: Function,
      default: () => {},
    },
    tableItem: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 0,
    },
  },

  methods: {
    clickAction(iconAction) {
      if (iconAction === 'Delete') {
        this.$store.dispatch('utils/_api_request_handler/show_confirm_dialog', {
          title: 'workloads.list.deleteWorkload',
          subTitle: 'workloads.list.removeSubTitle',
          callback: async () => {
            try {
              await this.$store.dispatch(this.tableItem.type !== 'docker-compose'
                ? 'workloads/delete_workload' : 'workloads/delete_compose_workload', this.tableItem);
              this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
                text: 'workloads.list.successfullyDeletedWorkload',
                color: 'success',
                showClose: true,
              });
              this.action = 'delete';
              this.fetchAction()(this.action);
            } catch (e) {
              Logger.error(e);
            }
          },
        });
      }
      if (iconAction === 'Disable' || iconAction === 'Enable') {
        this.$store.dispatch('utils/_api_request_handler/show_confirm_dialog', {
          title: 'workloads.list.disableConfirm',
          subTitle: 'workloads.list.disableSubTitle',
          callback: async () => {
            try {
              await this.$store.dispatch(this.tableItem.type !== 'docker-compose'
                ? 'workloads/disable_enable_workload' : 'workloads/disable_enable_compose_workload', this.tableItem);
              await this.fetchAction()();
            } catch (e) {
              Logger.error(e);
            }
          },
        });
      }
    },
  },

};
</script>

<style scoped>
  .inline {
    display: inline-block;
  }
</style>
